var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('aom-languages',[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:"resourceType",attrs:{"rules":{ required: true },"vid":"resourceType","name":"Resource Type"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Resource Type","label-for":"resourceType"}},[_c('v-select',{attrs:{"id":"resourceType","label":"text","options":_vm.resourceTypesSelect,"placeholder":"Select the Resource Type"},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12"}},[(_vm.showFileInput)?[_c('validation-provider',{ref:"addFile",attrs:{"rules":{ required: true },"vid":"addFile","name":"File"}},[_c('b-form-group',{attrs:{"label":"Add File (Allowed file extensions .doc, .docx, .docm, .dotx, .txt, .xls, .xlsx, .csv, .xlt, .xlsm, .ppt, .pptx, .pptm, .m4a, .wav, .aac, .mp3, .rar, .zip, .pdf, .png, .jpg, .gif, .bmp, .tif, .mp4, .avi, .mpg)","label-for":"addFile"}},[_c('b-form-file',{ref:"refInputEl",attrs:{"id":"addFile","state":Boolean(_vm.blogFile),"placeholder":"Add file","accept":_vm.resourceMimeTypes,"drop-placeholder":"Drop file here...","aria-invalid":Boolean(_vm.blogFile)
                      ? 'true'
                      : 'false'},model:{value:(_vm.blogFile),callback:function ($$v) {_vm.blogFile=$$v},expression:"blogFile"}}),_c('b-form-invalid-feedback',{attrs:{"state":Boolean(_vm.blogFile)}},[_vm._v(" "+_vm._s("File field is required")+" ")])],1)],1)]:_vm._e(),(_vm.showURLInput)?_c('validation-provider',{ref:"link",attrs:{"rules":{ required: true, url_with_protocol: true },"vid":"link","name":"URL"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"URL","label-for":"link"}},[_c('b-form-input',{attrs:{"id":"link","placeholder":"https://","state":_vm.getValidationState(validationContext),"aria-invalid":_vm.getValidationState(validationContext)
                    ? 'true'
                    : 'false'},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}):_vm._e(),(_vm.showWebPageInput)?_c('validation-provider',{ref:"page_title",attrs:{"rules":{ required: true },"vid":"page_title","name":"Page Title"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Page Title","label-for":"page_title"}},[_c('b-form-input',{attrs:{"id":"page_title","placeholder":"","state":_vm.getValidationState(validationContext),"aria-invalid":_vm.getValidationState(validationContext)
                    ? 'true'
                    : 'false'},model:{value:(_vm.page_title),callback:function ($$v) {_vm.page_title=$$v},expression:"page_title"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}):_vm._e(),(_vm.showWebPageInput)?_c('validation-provider',{ref:"page_content",attrs:{"rules":"required","vid":"page_content","name":"Page Content"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('h5',[_vm._v("Page Content")]),(_vm.shouldShowTextEditor)?_c('aom-text-editor',{attrs:{"state":_vm.getValidationState(validationContext),"height":100,"tool-bar":_vm.toolBar},model:{value:(_vm.page_content),callback:function ($$v) {_vm.page_content=$$v},expression:"page_content"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)}):_vm._e()],2)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:"title",attrs:{"rules":{ required: true },"vid":"title","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Title","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":"Title","state":_vm.getValidationState(validationContext),"aria-invalid":!_vm.getValidationState(validationContext)
                    ? 'true'
                    : 'false'},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)],1),(_vm.categoriesDisplay && _vm.categoriesDisplay.length)?_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:"category_resource",attrs:{"rules":{ required: true },"vid":"category_resource","name":"Category"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Category","label-for":"category_resource"}},[_c('v-select',{attrs:{"id":"category_resource","dir":_vm.dir,"label":"text","options":_vm.categoriesDisplay,"placeholder":"Select the Categories"},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)],1):_vm._e(),_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Select type of filter to use","label-for":"recipient"}},[_c('v-select',{attrs:{"disabled":!_vm.isProgramResource,"label":"name","options":_vm.filterOptions,"placeholder":"Select type of filter to use"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                    var name = ref.name;
return [_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(name))])])],1)]}}],null,true),model:{value:(_vm.userFilterType),callback:function ($$v) {_vm.userFilterType=$$v},expression:"userFilterType"}})],1),(_vm.userFilterType.name === 'Group')?_c('validation-provider',{attrs:{"is-required":_vm.userFilterType.name === 'Group' ? true : false,"vid":"recipient","name":"Recipient"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"mb-0 mt-2",attrs:{"label":"Recipient","label-for":"recipient"}},[_c('v-select',{attrs:{"label":"name","options":_vm.programUserGroups,"loading":_vm.isLoadingGroups,"placeholder":"Select a Group","state":_vm.getValidationState(
                validationContext
              )},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var name = ref.name;
              var type = ref.type;
return [_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(name))]),_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v(" "+_vm._s(type)+" ")])],1)],1)]}},{key:"selected-option",fn:function(ref){
              var avatar = ref.avatar;
              var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.recipientGroup),callback:function ($$v) {_vm.recipientGroup=$$v},expression:"recipientGroup"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                validationContext
              )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}):_vm._e(),(_vm.showUserRoleInput && _vm.userFilterType.name === 'Roles')?_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('user-roles-select',{attrs:{"is-required":_vm.userFilterType.name === 'Roles' ? true : false,"list-type":_vm.userRolesListType},on:{"update":_vm.updateSelectedUserRole},model:{value:(_vm.selectedUserRole),callback:function ($$v) {_vm.selectedUserRole=$$v},expression:"selectedUserRole"}})],1)],1):_vm._e(),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('b-form-group',{attrs:{"label":"Visible to public","label-for":"isPublic"}},[_c('b-form-checkbox',{attrs:{"id":"isPublicSwitch","switch":"","inline":""},model:{value:(_vm.isPublic),callback:function ($$v) {_vm.isPublic=$$v},expression:"isPublic"}})],1)],1)],1),(_vm.showAvailableSection)?[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"mt-1"},[_vm._v("Available")])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"12","lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","vid":"show-when","name":"When"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"show"}},[_c('v-select',{attrs:{"id":"show-when","label":"name","placeholder":"When","options":_vm.eventTriggersDisplay,"state":_vm.getValidationState(
                      validationContext
                    )},model:{value:(_vm.happenWhen),callback:function ($$v) {_vm.happenWhen=$$v},expression:"happenWhen"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                      validationContext
                    )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12","lg":"8"}},[_c('validation-provider',{attrs:{"rules":"required","vid":"show-actionable-event","name":"Event"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"show"}},[_c('aom-events-select',{attrs:{"id":"show-actionable-event","options":_vm.eventsDisplay,"state":_vm.getValidationState(
                      validationContext
                    )},model:{value:(_vm.actionableEvent),callback:function ($$v) {_vm.actionableEvent=$$v},expression:"actionableEvent"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                      validationContext
                    )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),(_vm.showCustomDateField)?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('aom-date-picker',{attrs:{"min":_vm.minCustomDate},model:{value:(_vm.scheduledDate),callback:function ($$v) {_vm.scheduledDate=$$v},expression:"scheduledDate"}})],1)],1):_vm._e()],1),(_vm.showPeriodField)?_c('b-col',{attrs:{"md":"12","lg":"6"}},[_c('validation-provider',{attrs:{"vid":"time","rules":{ required: true },"name":"Period"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"show"}},[_c('v-select',{attrs:{"id":"time","label":"#","placeholder":"Period","options":_vm.periodList,"state":_vm.getValidationState(
                      validationContext
                    )},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                      validationContext
                    )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1):_vm._e(),(_vm.showDurationField)?_c('b-col',{attrs:{"md":"12","lg":"6"}},[_c('validation-provider',{attrs:{"vid":"duration","rules":{ required: true },"name":"Duration"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"show"}},[_c('v-select',{attrs:{"id":"duration","label":"name","placeholder":"Duration","options":_vm.durationUnitsDisplay,"state":_vm.getValidationState(
                      validationContext
                    )},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                      validationContext
                    )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1):_vm._e()],1)]:_vm._e(),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function($event){return _vm.onReset()}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary","disabled":invalid},on:{"click":function($event){return handleSubmit(_vm.onSubmit)}}},[(_vm.isUpdating)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.isUpdating)?_c('span',[_vm._v(" Updating...")]):_c('span',[_vm._v("Submit")])],1)],1)],1)],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }